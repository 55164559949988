import React, {useEffect, useState} from 'react';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import MaterialTable from '@material-table/core'
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import 'moment-timezone';
import {tableIcons} from "../styles";
import {formatFloat} from "../helpers";
import ImagesDialog from "./ImagesDialog";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {useLocalStorage} from "../context/useLocalStorage";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function LightControlRawDataTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [selectedIdLms, setSelectedIdLms] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [images, setImages] = useState([]);

    const [hiddenColumns, setHiddenColumns] = useLocalStorage("lightControlRawDataTableHiddenColumns", []);
    const columns = [
        {title: 'id_lms', field: 'id_lms', type: 'numeric', defaultSort: 'desc'},
        {
            title: 'datetime',
            field: 'datetime',
            render: rowData => moment(rowData.datetime)?.tz('Etc/GMT')?.format('YYYY-MM-DD HH:mm:ss'),
            customSort: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
            cellStyle: {
                minWidth: 150,
                maxWidth: 150
            }
        },
        {title: 'lightcontrol', field: 'lightcontrol.serialnumber'},
        {title: 'brand', field: 'endoscope_serial.masterscope_library.endoscope_brand.brand'},
        {title: 'type', field: 'endoscope_serial.masterscope_library.scopetype'},
        {title: 'serial', field: 'endoscope_serial.serialnumber'},
        {title: 'datamatrix', field: 'endoscope_serial.datamatrixcode'},
        {title: 'rx', field: 'rx', type: 'numeric', render: rowData => formatFloat(rowData.rx)},
        {title: 'tx', field: 'tx', type: 'numeric', render: rowData => formatFloat(rowData.tx)},
        {
            title: 'cchpresult',
            field: 'cchpresult',
            type: 'numeric',
            render: rowData => formatFloat(rowData.cchpresult)
        },
        {
            title: 'cchpthreshold',
            field: 'cchpthreshold',
            type: 'numeric',
            render: rowData => formatFloat(rowData.cchpthreshold)
        },
        {
            title: 'ccoriginalvalue',
            field: 'ccoriginalvalue',
            type: 'numeric',
            render: rowData => formatFloat(rowData.ccoriginalvalue)
        },
        {title: 'ccresult', field: 'ccresult'},
        {
            title: 'ccuncorrected',
            field: 'ccuncorrected',
            type: 'numeric',
            render: rowData => formatFloat(rowData.ccuncorrected)
        },
        {
            title: 'ccreference',
            field: 'ccreference',
            type: 'numeric',
            render: rowData => formatFloat(rowData.ccreference)
        },
        {
            title: 'fbhpresult',
            field: 'fbhpresult',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fbhpresult)
        },
        {
            title: 'fbhpthreshold',
            field: 'fbhpthreshold',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fbhpthreshold)
        },
        {
            title: 'fboriginalvalue',
            field: 'fboriginalvalue',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fboriginalvalue)
        },
        {title: 'fbresult', field: 'fbresult'},
        {
            title: 'fbuncorrected',
            field: 'fbuncorrected',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fbuncorrected)
        },
        {
            title: 'fbreference',
            field: 'fbreference',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fbreference)
        },
        {
            title: 'fchpresult',
            field: 'fchpresult',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fchpresult)
        },
        {
            title: 'fchpthreshold',
            field: 'fchpthreshold',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fchpthreshold)
        },
        {
            title: 'fcoriginalvalue',
            field: 'fcoriginalvalue',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fcoriginalvalue)
        },
        {title: 'fcresult', field: 'fcresult'},
        {
            title: 'fcuncorrected',
            field: 'fcuncorrected',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fcuncorrected)
        },
        {
            title: 'fcreference',
            field: 'fcreference',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fcreference)
        },
        {
            title: 'lthpresult',
            field: 'lthpresult',
            type: 'numeric',
            render: rowData => formatFloat(rowData.lthpresult)
        },
        {
            title: 'lthpthreshold',
            field: 'lthpthreshold',
            type: 'numeric',
            render: rowData => formatFloat(rowData.lthpthreshold)
        },
        {
            title: 'ltoriginalvalue',
            field: 'ltoriginalvalue',
            type: 'numeric',
            render: rowData => formatFloat(rowData.ltoriginalvalue)
        },
        {title: 'ltresult', field: 'ltresult'},
        {
            title: 'ltuncorrected',
            field: 'ltuncorrected',
            type: 'numeric',
            render: rowData => formatFloat(rowData.ltuncorrected)
        },
        {
            title: 'ltreference',
            field: 'ltreference',
            type: 'numeric',
            render: rowData => formatFloat(rowData.ltreference)
        },
        {
            title: 'pdoriginalvalue',
            field: 'pdoriginalvalue',
            type: 'numeric',
            render: rowData => formatFloat(rowData.pdoriginalvalue)
        },
        {title: 'pdresult', field: 'pdresult'},
        {
            title: 'lforiginalvalue',
            field: 'lforiginalvalue',
            type: 'numeric',
            render: rowData => formatFloat(rowData.lforiginalvalue)
        },
        {title: 'lfresult', field: 'lfresult'},
        {title: 'ltshutterspeed', field: 'ltshutterspeed', type: 'numeric'},
        {title: 'totalresult_pack1', field: 'totalresult_pack1'},
        {title: 'totalresult_pack2', field: 'totalresult_pack2'},
        {title: 'totalresult_pack3', field: 'totalresult_pack3'},
        {title: 'encoder_trigger_position', field: 'encoder_trigger_position', type: 'numeric'},
        {title: 'total_measurement_seconds', field: 'total_measurement_seconds', type: 'numeric'},
    ].map((column) => {
        column.hidden = hiddenColumns.includes(column.field);
        return column;
    });

    const handleColumnHiddenChange = (column, hidden) => {
        column.hidden = hidden;
        const updatedHiddenColumns = hidden ? [...hiddenColumns, column.field] : hiddenColumns.filter(col => col !== column.field);
        setHiddenColumns(updatedHiddenColumns);
    };

    const handleClickOpen = (rowData) => {
        setSelectedIdLms(rowData.id_lms);
        setSelectedRowData(rowData); // Store the selected row data
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(selectedIdLms) {
            setLoadingImages(true)
            setImages([]);

            flaskAPI.get("/api/data/lc/images?id_lms=" + selectedIdLms).then(response => {
                if (response.status === 200) {
                    setImages(response.data);
                    setLoadingImages(false)
                } else {
                    // TODO error message
                }
            })
                .catch(error => {
                    setLoadingImages(false)
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [selectedIdLms, setAuthTokens]);


    return (
        <>
            <ImagesDialog onClose={handleClose} images={images} loading={loadingImages} aria-labelledby="customized-dialog-title" open={open} rowData={selectedRowData} columns={columns} />
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="LC - Raw measurements"
                columns={columns}
                data={props.data}
                onRowClick={(event, rowData, togglePanel) => handleClickOpen(rowData)}
                onChangeColumnHidden={handleColumnHiddenChange}
                options={{
                    columnsButton: true,
                    padding: "dense",
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportMenu: [{
                        label: 'Export CSV',
                        exportFunc: (cols, datas) => ExportCsv(cols, datas, 'dovidash_export')
                    }],
                    headerStyle: {
                        fontSize: 12,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
                actions={[
                    /*
                    {
                        icon: () => <PictureAsPdfIcon />,
                        tooltip: 'Genereate PDF',
                        isFreeAction: true,
                        onClick: (event) => props.onOpenReport()
                    }
                     */
                ]}
            />
        </>
    );
}

export default withStyles(styles)(LightControlRawDataTable);
